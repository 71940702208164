import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
import router from '@/router';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function get(params) {
  console.log('params', params);
  return storeRequest.get('/store-data-transfer', {
    params: { ...params },
  });
}
function getById(id) {
  return storeRequest.get('/store-data-transfer/' + id);
}
function create(data) {
  console.log('params', data);
  return storeRequest.post('/store-data-transfer', {
    storeDataTransfer: data,
  });
}
function update(data) {
  console.log('params', data);
  return storeRequest.put('/store-data-transfer/' + data._id, {
    storeDataTransfer: data,
  });
}
function remove(id) {
  return storeRequest.delete('/store-data-transfer/' + id);
}

export const migrateApi = {
  create,
  get,
  remove,
  update,
  getById,
};
